@use '@angular/material' as mat;
$md-primary: (
  50: #e5eff5,
  100: #bed7e6,
  200: #93bcd5,
  300: #67a1c4,
  400: #478cb7,
  500: #2678aa,
  600: #2270a3,
  700: #1c6599,
  800: #175b90,
  900: #0d487f,
  A100: #b1d6ff,
  A200: #7ebbff,
  A400: #4ba0ff,
  A700: #3192ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
/* For use in src/lib/core/theming/_palette.scss */
$md-accent: (
  50: #edeef0,
  100: #d3d5d9,
  200: #b5b9c0,
  300: #979ca6,
  400: #818793,
  500: #6b7280,
  600: #636a78,
  700: #585f6d,
  800: #4e5563,
  900: #3c4250,
  A100: #aac3fe,
  A200: #779ffe,
  A400: #427bff,
  A700: #2969ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ChatbotFrontUpdate-primary: mat.define-palette($md-primary);
$ChatbotFrontUpdate-accent: mat.define-palette($md-accent);
// The warn palette is optional (defaults to red).
$ChatbotFrontUpdate-warn: mat.define-palette(mat.$red-palette);
