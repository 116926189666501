@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import 'tailwindcss/base';
@include mat.core();
@import './assets/styles/app-color-palette.scss';
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ChatbotFrontUpdate-theme: mat.define-light-theme(
  (
    color: (
      primary: $ChatbotFrontUpdate-primary,
      accent: $ChatbotFrontUpdate-accent,
      warn: $ChatbotFrontUpdate-warn,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ChatbotFrontUpdate-theme);
/* You can add global styles to this file, and also import other style files */
:root {
  --sidebar-width: 100%;
  --sidebar-close-width: 0%;
  --header-height: 8vh;
  --footer-height: 7vh;
}
@media (min-width: 768px) {
  :root {
    --sidebar-width: 18%;
    --sidebar-close-width: 4rem;
    --sidebar-logout-height: 10vh;
  }
}
html,
body {
  height: 100%;
  @apply bg-secondary;
}
* {
  margin: 0;
  @apply font-roboto antialiased;
  box-sizing: border-box;
}
.font-current {
  @apply font-roboto antialiased;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
@layer utilities {
  .rotate-x-180 {
    transform: rotatex(180deg);
  }
  .sidebar.open {
    width: var(--sidebar-width);

    .sidebar-wrapper {
      min-height: calc(
        100vh - var(--header-height) - var(--sidebar-logout-height) - 1.25rem
      );
      max-height: calc(
        100vh - var(--header-height) - var(--sidebar-logout-height) - 1.25rem
      );
      @apply px-4;
    }
    .navigation-label,
    .toggle-icon {
      display: inline-block;
    }
    .navigation-label {
      @apply self-center text-sm;
    }
    .nav-category {
      @apply text-start text-base;
    }
    .nav-button {
      @apply justify-start p-2;
    }
    .sidebar-logout {
      @apply hidden md:flex;
    }
    .logo-avatar {
      @apply hidden;
    }
    .logo {
      @apply inline-block;
    }
  }
  .sidebar {
    width: var(--sidebar-close-width);
    transition: all 0.5s ease;
    @apply fixed overflow-x-hidden   border-r border-r-accent-100;
    .navigation-label,
    .toggle-icon {
      display: none;
    }
    .sidebar-wrapper {
      min-height: calc(100vh - var(--header-height) - 1.25rem);
      max-height: calc(100vh - var(--header-height) - 1.25rem);
      @apply overflow-x-hidden overflow-y-scroll p-1 py-2;
    }
    .nav-category {
      @apply py-1 text-center text-base font-medium uppercase;
    }
    .nav-button {
      @apply flex w-full items-center  justify-center rounded-md px-0 py-2  transition duration-75 hover:bg-accent-100;
      .icon {
        @apply h-4 w-4 text-[1.2rem];
      }
    }
    .sidebar-logout {
      @apply hidden;
    }
    .logo-avatar {
      @apply flex w-full items-center justify-center;
    }
    .logo {
      @apply hidden;
    }
  }
  .sidebar.open ~ .content {
    left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    @apply hidden md:block;
  }
  .content {
    position: relative;
    min-height: 100vh;
    top: 0;
    left: var(--sidebar-close-width);
    width: calc(100% - var(--sidebar-close-width));
    transition: all 0.5s ease;
    z-index: 2;
    // overflow-x: hidden;
  }
  .backdrop {
    background-color: rgba(26, 25, 25, 0.1);
  }
}

label.switch {
  @apply relative inline-flex cursor-pointer items-center;
  input[type='checkbox'] {
    @apply sr-only hidden;
  }
  div {
    @apply h-4 w-7 rounded-full  bg-accent-200  after:absolute after:start-[2px] after:top-[2px] after:h-3 after:w-3 after:rounded-full after:bg-secondary after:transition-all after:content-[''];
  }
  input[type='checkbox']:checked ~ div {
    @apply translate-x-full bg-primary-600;
  }
  input[type='checkbox']:checked ~ div {
    @apply outline-none;
  }
}

::-webkit-scrollbar {
  width: 0.4rem; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 0.4rem;
  @apply rounded-full bg-primary;
}
::-webkit-scrollbar:horizontal {
  height: 0.2rem;
}

.snackbar-info {
  @apply bg-primary;
}
.snackbar-error {
  @apply bg-red-500;
}
.snackbar-warning {
  @apply bg-amber-500;
}
.snackbar-success {
  @apply bg-emerald-800;
}
.mat-mdc-snack-bar-container {
  &.snackbar-info {
    --mdc-snackbar-container-color: rgb(38 120 170);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.snackbar-error {
    --mdc-snackbar-container-color: rgb(221, 18, 18);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.snackbar-warning {
    --mdc-snackbar-container-color: rgb(209, 223, 15);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.snackbar-success {
    --mdc-snackbar-container-color: rgb(6 95 70);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}
.hidden-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
@import '../node_modules/quill/dist/quill.snow.css';
